import * as React from 'react';
import { map as _map } from 'lodash';
import Flex from '@lce/slice_v2/Layout/Flex';
import Link from '@lce/slice_v2/Elements/Link';
import Heading from '@lce/slice_v2/Elements/Heading';
import { ILocationButton } from '@lce/intl-types/src/ILayout';

import Container from '../../Layout/Container';

import OrderButton from './Components/OrderButton';
import LocationButton from './Components/LocationButton';

export interface INavigationProps {
  locale: {
    uri: string;
    locale: string;
  };
  preNavButton?: ILocationButton;
  postNavButton?: INavButtonProps;
  navigationLinks: INavigationLinkProps[];
}
export interface INavButtonProps {
  background: boolean;
  icon: {
    url: string;
  };
  text: string;
  urlComponent: string;
}

export interface INavigationLinkProps {
  name: string;
  url: string;
  id: string;
}

const Navigation: React.FC<INavigationProps> = ({
  navigationLinks,
  preNavButton,
  postNavButton,
  locale: { locale },
}) => (
  <Container sx={{ alignItems: 'center', display: 'flex', flexDirection: ['column', 'row'], px: 3, my: 2 }}>
    {preNavButton?.selectedFranchiseeId && <LocationButton sx={{ position: [ 'relative' , 'absolute' ] }} {...preNavButton} />}
    <Flex
      sx={{
        alignItems: 'center',
        flex: '1 1 auto',
        justifyContent: 'center',
        flexDirection: ['column', 'row', 'row'],
        p: '8px 0',
      }}
    >
      {_map(navigationLinks, ({ name, url, id }) => (
        <Link
          href={`/${locale.toLocaleLowerCase()}${url}`}
          key={id}
          sx={{
            m: ['0 8px', '0 4px', '0 8px'],
            p: ['8px 16px', '4px 16px', '8px 16px'],
          }}
          variant='mainNav'
        >
          <Heading as='h2' sx={{ fontSize: [ 10, 8, 10 ], my: '8px' }}>{name}</Heading>
        </Link>
      ))}
      {postNavButton && <OrderButton {...postNavButton} locale={locale} />}
    </Flex>
  </Container>
);
export default Navigation;
