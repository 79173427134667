import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import Link from '@lce/slice_v2/Elements/Link';
import Image from '@lce/slice_v2/Elements/Image';

import LcLogo from './assets/lc-logo.png';
import RuLogo from './assets/lc-logo-ru.png';
import * as Styled from './Header.style';

export interface IHeaderProps {
  locale: string;
}

const Header: React.FC<IHeaderProps> = ({ locale }) => (
  <Box sx={Styled.Header}>
    <Link href="/">
      { 
        locale === 'ru-RU' ? 
          <Image data-testid="russian-image" src={RuLogo} sx={Styled.LCLogo} />
          : 
          <Image data-testid="english-image" src={LcLogo} sx={Styled.LCLogo} />
      }
    </Link>
  </Box>
);

export default Header;
