import { graphql, useStaticQuery } from 'gatsby';

import { localeFilterList } from '@lce/intl-util/src/localeFilter';

export interface ILeavingDialogProps {
  leavingDialog: INodeLeavingDialogProps;
}

interface INodeLeavingDialogProps {
  nodes: IDatoNavigationProps[];
}

interface IDatoNavigationProps {
  locale: string;
  text: string;
  legalText: string;
  image: {
    url: string;
  };
  modalName: string;
  orderingLinks: IOrderingLinksProp[];
  selectionText: string;
}

interface IOrderingLinksProp {
  id: string;
  linkTitle: string;
  link: string;
  logo: {
    alt?: string;
    url: string;
  };
}

// eslint-disable-next-line max-lines-per-function
const useLeavingDialog = (locale: string) => {
  const data: ILeavingDialogProps = useStaticQuery(graphql`
    query LeavingDialogQuery {
      leavingDialog: allDatoCmsModal {
        nodes {
          locale
          text
          legalText
          image {
            url
          }
          modalName
          orderingLinks {
            id
            linkTitle
            link
            logo {
              alt
              url
            }
          }
          selectionText
        }
      }
    }
  `);

  const filteredLeavingDialogProps = localeFilterList<IDatoNavigationProps>(
    data.leavingDialog.nodes,
    locale,
  );

  return {
    ...filteredLeavingDialogProps,
  };
};
export default useLeavingDialog;
