import { graphql, useStaticQuery } from 'gatsby';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import localeFilter from '@lce/intl-util/src/localeFilter';
import { INavigationLinkProps, INavButtonProps } from '@lce/intl-ui/src/Patterns/Navigation';

import useLeavingDialog from '../LeavingDialog';

export interface ILayoutProps {
  footer: INodeFooterProps;
  navigation: INodeNavigationProps;
  footerNavigation: INodeNavigationProps;
  socialMediaLink: IDatoSocialMediaLinks;
}

interface INodeFooterProps {
  nodes: IDatoFooterProps[];
}

interface IDatoFooterProps {
  locale: string;
  copyrightStartingYear: string;
  legalText: string;
  socialMediaText: string;
  subLegalTextNode: { childMdx: { body: any } };
}

interface INodeNavigationProps {
  nodes: IDatoNavigationProps[];
}

interface IDatoNavigationProps {
  locale: string;
  postNavButton?: INavButtonProps;
  navigationLinks: IDatoNavigationLinkProps[];
}

interface IDatoNavigationLinkProps {
  link: INavigationLinkProps;
}

interface IDatoSocialMediaLinks {
  nodes: ISocialMediaLink[];
}

interface ISocialMediaLink {
  url: string;
  position: string;
}

// eslint-disable-next-line max-lines-per-function
const useLayout = (locale: string) => {
  const data: ILayoutProps = useStaticQuery(graphql`
    query LayoutQuery {
      navigation: allDatoCmsNavigation(filter: { navigationSlug: { eq: "main-nav" } }) {
        nodes {
          locale
          navigationSlug
          postNavButton {
            background
            urlComponent
            text
            icon {
              url
            }
          }
          preNavButton {
            background
            icon {
              url
            }
            text
            urlComponent
          }
          navigationLinks {
            link {
              name
              url
              id
            }
          }
        }
      }
      footer: allDatoCmsFooter {
        nodes {
          locale
          legalText
          subLegalTextNode {
            childMdx {
              body
            }
          }
          copyrightStartingYear
          socialMediaText
        }
      }
      footerNavigation: allDatoCmsNavigation(filter: { navigationSlug: { eq: "footer-nav" } }) {
        nodes {
          locale
          navigationSlug
          postNavButton {
            background
            urlComponent
            text
            icon {
              url
            }
          }
          preNavButton {
            background
            icon {
              url
            }
            text
            urlComponent
          }
          navigationLinks {
            link {
              name
              url
              id
            }
          }
        }
      }
      socialMediaLink: allDatoCmsSocialMediaLink {
        nodes {
          url
          position
        }
      }
    }
  `);
  const dialogs = useLeavingDialog(locale);
  let leavingToOrderingDialog;
  
  _map(dialogs, dialog => {
    if(dialog.modalName === 'LeavingToOrderingDialog') {
      leavingToOrderingDialog = dialog;
    }
  });

  const filteredNavigationLinks = localeFilter<IDatoNavigationProps>(data.navigation.nodes, locale);
  const navigation = filteredNavigationLinks
    ? _map(filteredNavigationLinks.navigationLinks, ({ link: { name, url, id } }) => ({
      name,
      url,
      id,
    }))
    : [];
  const filteredFooter = localeFilter(data.footer.nodes, locale);
  const filteredFooterNavigation = localeFilter(data.footerNavigation.nodes, locale);

  const footerNavigation = filteredFooterNavigation
    ? _map(filteredFooterNavigation.navigationLinks, ({ link: { name, url, id } }) => ({
      name,
      url,
      id,
    }))
    : [];
  
  const sortedSocialLinks = _sortBy(data.socialMediaLink.nodes, (link) => link.position);
  
  const socialMediaLinks: string[] = [];

  _forEach(sortedSocialLinks, (link) => socialMediaLinks.push(link.url));

  const postNavButton = filteredNavigationLinks?.postNavButton?.urlComponent === 'LeavingToOrderingDialog'
    ? { ...filteredNavigationLinks.postNavButton, dialog: leavingToOrderingDialog }
    : filteredNavigationLinks?.postNavButton;

  return {
    footer: {
      ...filteredFooter,
      subLegalText: filteredFooter && filteredFooter.subLegalTextNode && filteredFooter.subLegalTextNode.childMdx.body,
    },
    footerNavigation: {
      ...filteredFooterNavigation,
      navigationLinks: footerNavigation,
    },
    navigation: {
      preNavButton: undefined,
      postNavButton,
      navigationLinks: navigation,
    },
    socialMediaLinks,
  };
};

export default useLayout;
