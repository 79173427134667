import { SxStyleProp } from 'theme-ui';

export const LeavingDialog: SxStyleProp = {
  flexDirection: 'column',
};

export const Headline: SxStyleProp = {
  color: 'white',
  textAlign: 'center',
  lineHeight: '1em',
  fontSize: 16,
  my: '8px 0',
};

export const Instructions: SxStyleProp = {
  color: 'white',
  textAlign: 'center',
  margin: '20px 0 8px 0',
  fontSize: 12,
};

export const CaesarManImage: SxStyleProp = {
  m: '20px auto 0',
  width: '150px',
};

export const AnchorList: SxStyleProp = {
  flexDirection: ['column', 'row'],
  mt: '12px',
  minHeight: '1px',
  alignItems: 'center',
};

export const Anchor: SxStyleProp = {
  color: 'white',
  m: '0 auto',
  p: ['16px 20px', '8px 20px'],
  cursor: 'pointer',
};

export const DeliveryIcon: SxStyleProp = {
  width: '100%',
  height: 'auto',
  minHeight: '1px',
  minWidth: '92px',
  verticalAlign: 'middle',
  mb: '0',
};

export const LegalText: SxStyleProp = {
  color: 'black',
  textAlign: 'center',
  fontSize: 6,
  lineHeight: '1rem',
  m: '32px 8px',
};
