import { SxStyleProp } from 'theme-ui';

export const Header: SxStyleProp = {
  bg: 'primary',
  width: '100%',
  height: '70px',
  pt: '1px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'flex-end',
};

export const LCLogo: SxStyleProp = {
  height: ['50px', '69px'],
  width: ['288px', '397px'],
  verticalAlign: 'middle',
};
