import * as React from 'react';
import Layout from '@lce/intl-ui/src/Layout/Layout';
import useSiteConfig from '@lce/gatsby-theme-international/src/api/Config/useSiteConfig';
import usePageSeo from '@lce/gatsby-theme-international/src/api/PageSeo/usePageSeo';
import useLayout from '@lce/gatsby-theme-international/src/api/Layout';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';

const Home: React.FC<IDefaultPageProps> = (props) => {
  const { locale } = props.pathContext;

  const pageSeo = usePageSeo(props);
  const layoutProps = useLayout(locale);
  const { direction } = useSiteConfig(locale);
  
  return (
    <Layout {...props} {...pageSeo} {...layoutProps} direction={direction} >
      {props.children}
    </Layout>
  );
};

export default Home;
