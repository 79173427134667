import * as React from 'react';
import SliceProvider from '@lce/slice_v2/SliceProvider';
import IePolyfill from '@lce/intl-util/src/IePolyfill';
import { Direction as IDirection } from '@lce/intl-types/src/IDirection';
import { IFooter, INavigation } from '@lce/intl-types/src/ILayout';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import { fontDefinitions, theme } from '@lce/gatsby-theme-multi-site/src/ui/theme';

import Footer from '../../Patterns/Footer';
import Header from '../../Patterns/Header';
import Seo from '../../Patterns/Seo';
import Navigation from '../../Patterns/Navigation';
import Direction from '../../Patterns/Direction';

export interface ILayoutProps extends IDefaultPageProps {
  footer: IFooter;
  navigation: INavigation;
  footerNavigation: INavigation;
  socialMediaLinks: string[];
  direction: IDirection;
  title?: string;
  description?: string;
}

const Layout: React.FC<ILayoutProps> = (props) => {
  const {
    children, 
    uri, 
    pathContext: { locale }, 
    footer, 
    navigation, 
    footerNavigation, 
    socialMediaLinks, 
    direction, 
    title, 
    description,
  } = props;
 
  return (
    <SliceProvider global={[fontDefinitions]} theme={theme}>
      <IePolyfill />
      <Direction direction={direction}>
        <Seo description={description} title={title} />
        <Header locale={locale} />
        <Navigation {...navigation} locale={{ uri, locale }} />
        {children}
        <Footer
          {...footer}
          {...footerNavigation}
          locale={locale}
          socialMediaLinks={socialMediaLinks}
        />
      </Direction>
    </SliceProvider>
  );
};

export default Layout;
