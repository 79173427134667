import * as React from 'react';
import { find as _find } from 'lodash';
import Button from '@lce/slice_v2/Elements/Button';
import Image from '@lce/slice_v2/Elements/Image';
import Heading from '@lce/slice_v2/Elements/Heading';
import { ILocationButton } from '@lce/intl-types/src/ILayout';

import Pin from './assets/locator_pin_orange.png';

const LocationButton: React.FC<ILocationButton> = ( { onClick, selectedFranchiseeId, franchisees, sx, ...props } ) => {
  const franchiseeName = _find(franchisees, (franchisee) => franchisee.id === selectedFranchiseeId)?.name;

  return franchisees.length > 1 ? (
    <Button
      data-testid="LocationButton"
      onClick={() => onClick()}
      sx={{
        backgroundColor: 'white',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black',
        border: 'none',
        p: '0 !important',
        m: '0 !important',
        ...sx,
      }}
      {...props}
    >
      <Image alt="" src={Pin} sx={{ width: '12px', height: 'auto', margin: 0 }} />
      <Heading as='h2' sx={{ color: 'inherit', fontSize: [ 10, 8, 10 ], my: '8px', px: '8px', textTransform: 'uppercase', textDecoration: 'underline' }}>{franchiseeName}</Heading>
    </Button>
  ) : null;
};

export default LocationButton;
