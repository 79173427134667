import { SxStyleProp } from 'theme-ui';

export const ModalBox: SxStyleProp = {
  position: 'relative',
  zIndex: '1000',
  maxWidth: '600px',
  mx: 'auto',
  my: ['0', '5%'],
  width: ['100%', '75%'],
};

export const Body: SxStyleProp = {
  p: '5px',
  pt: '0',
};

export const Header: SxStyleProp = {
  justifyContent: 'space-between',
  p: '20px',
  width: '100%',
};

export const ModalTitle: SxStyleProp = {
  fontSize: 12,
  m: '0',
};

export const Button: SxStyleProp = {
  background: 'none',
  cursor: 'pointer',
  border: 'none',
  ':hover': {
    color: 'white',
  },
};
