/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { map as _map } from 'lodash';
import Flex from '@lce/slice_v2/Layout/Flex';
import Heading from '@lce/slice_v2/Elements/Heading';
import Image from '@lce/slice_v2/Elements/Image';
import Link from '@lce/slice_v2/Elements/Link';
import Box from '@lce/slice_v2/Layout/Box';
import { IOrderingLinksProp } from '@lce/intl-types/src/IOrderingLinksProp';
import checkCountryLocale from '@lce/intl-util/src/CheckCountryLocale';

import Modal from '../Modal';
import * as Styled from './LeavingDialog.style';

interface ILeavingSiteDialogProps {
  isOpen: boolean;
  onClose: (state: boolean) => void;
  dialog: IDialog;
}

export interface IDialog {
  text: string;
  legalText: string;
  image: {
    url: string;
  };
  modalName: string;
  orderingLinks: IOrderingLinksProp[];
  selectionText: string;  
}

const LeavingDialog: React.FC<ILeavingSiteDialogProps> = ({ isOpen, onClose, dialog: { text, image, selectionText, orderingLinks, legalText } }) => {

  const displayLargeLink = checkCountryLocale('-es') && orderingLinks.length === 1;

  const goToRequest = (url: string) => {
    onClose(false);
    window.open(url);
  };
  
  return (
    <Modal isOpen={isOpen} key={text} onClose={() => onClose(false)}>
      <Flex sx={Styled.LeavingDialog}>
        {text && (
          <Heading as="h2" sx={Styled.Headline}>
            {text}
          </Heading>
        )}
        {image && (
          <Image src={image.url} sx={Styled.CaesarManImage} />
        )}
        {selectionText && (
          <Heading as="h3" sx={Styled.Instructions}>
            {selectionText}
          </Heading>
        )}
        <Flex sx={Styled.AnchorList}>
          {_map(orderingLinks, (links, index) => (
            <Link
              data-testid={`ActionButton${index}`}
              key={links.id}
              onClick={() => goToRequest(links.link)}
              sx={{ ...Styled.Anchor,
                maxWidth: displayLargeLink ? '450px' : '168px' }
              }
              title={links.linkTitle}
            >
              <Image
                alt={links.logo.alt}
                src={links.logo.url}
                sx={Styled.DeliveryIcon}
              />
            </Link>
          ))}
        </Flex>
        <Box sx={Styled.LegalText}>{legalText}</Box>
      </Flex>
    </Modal>
  );
};

export default LeavingDialog;
