import * as React from 'react';
import Button from '@lce/slice_v2/Elements/Button';
import Image from '@lce/slice_v2/Elements/Image';
import Heading from '@lce/slice_v2/Elements/Heading';

import { INavButtonProps } from '../../Navigation';
import LeavingDialog from '../../../Modals/LeavingDialog';
import { IDialog } from '../../../Modals/LeavingDialog/LeavingDialog';

export interface INavButton extends INavButtonProps {
  locale: string;
  dialog?: IDialog;
}

const NavButton: React.FC<INavButton> = ({ urlComponent, text, icon: { url }, locale, background, dialog }) => {
  const [isOpen, setOpen] = React.useState(false);

  const isUrl = urlComponent.search('/') !== -1;

  const goToUrl = () => {
    if (urlComponent.search('http') >= 0) {
      window.location.assign(`${urlComponent}`);
    } else {
      window.location.assign(`${window.location.origin}/${locale.toLowerCase()}${urlComponent}`);
    }
  };
  
  return dialog && dialog?.orderingLinks?.length > 0 ? (
    <>
      <Button
        data-testid="OrderingButton"
        onClick={() => (isUrl ? goToUrl() : setOpen(true))}
        sx={{
          backgroundColor: background ? 'primary' : 'white',
          borderRadius: '12px',
          display: 'flex',
          height: '56px',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          color: background ? 'white' : 'black',
          margin: '8px 0',
          border: 'none',
          padding: '0 8px',
          ':hover': {
            textDecoration: 'underline',
            backgroundColor: background ? 'primary' : 'inherit',
            color: background ? 'white' : 'black',
          },
          m: '0 !important',
        }}
      >
        <Image alt="" src={url} sx={{ width: '40px', height: 'auto', margin: 0 }} />
        <Heading as='h2' sx={{ color: 'inherit', fontSize: [ 10, 8, 10 ], my: '8px', px: '8px', textTransform: 'uppercase' }}>{text}</Heading>
      </Button>
      {!isUrl && dialog && <LeavingDialog dialog={dialog} isOpen={isOpen} onClose={() => setOpen(false)} />}
    </>
  ) : null;
};

export default NavButton;
